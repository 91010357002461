<template>
  <TableLayout>
    <template v-slot:panel>
      <v-container fluid class="px-5 dark_blue">
        <v-row style="gap: 5px" class="flex-column py-1">
          <v-col class="elevation-5 col-ajust">
            <ParentTab :tab="parentTabValue" @changeTab="changeParentTab" />
          </v-col>
        </v-row>
        <Panel
          :tab="tab"
          :parentTab="parentTabValue"
          @changeTab="changeTab"
          :header="headerReport"
        />
      </v-container>
    </template>
    <template v-slot:table>
      <v-container fluid class="pa-0 table-panel-grid overflow-y-auto">
        <Table :header="header" :tab="tab" />
      </v-container>
    </template>
  </TableLayout>
</template>

<script>
import TableLayout from "@/layout/TableLayout.vue";
import { generateHeaderData } from "@/utils/tables.js";
import Table from "@/components/Reports/Table.vue";
import Panel from "@/components/Reports/Panel.vue";
import ParentTab from "@/components/Reports/ParentTab.vue";

export default {
  name: "Reports",
  components: {
    TableLayout,
    Table,
    Panel,
    ParentTab,
  },
  data() {
    return {
      reportData: [{ text: "Name", value: "info" }],
      tab: 0,
      filteredData: [],
      parentTabValue: 0,
    };
  },
  computed: {
    headerReport() {
      const tabValues =
        this.parentTabValue === 0
          ? [
              {
                title: "Sale Pending",
                index: 0,
              },
              {
                title: "Release Issues",
                index: 5,
              },
              {
                title: "Month End Report",
                index: 1,
              },
              {
                title: "Mass Rebill",
                index: 2,
              },
            ]
          : [
              {
                title: "Sale Pending",
                index: 0,
              },
              {
                title: "Month End Report",
                index: 1,
              },
            ];

      let headerReports = {
        "Sale Pending": [
          { text: "Container", value: "Container" },
          { text: "Size", value: "_Size" },
          { text: "Age", value: "Age" },
          { text: "Amount", value: "Amount" },
          { text: "Location", value: "Location" },
          { text: "Status", value: "Status" },
          { text: "Company Name", value: "Company_Name" },
          { text: "Supplier Number", value: "Supplier_Number" },
          { text: "Contact Name", value: "Contact_Name" },
          { text: "Date of Quote", value: "Date_of_Quote" },
          { text: "Date Sold", value: "Date_Sold" },
          { text: "Payment Recvd", value: "Payment_Recvd" },
          { text: "Release sent DATE", value: "Release_sent" },
          // { text: "Release", value: "some calculated value" }, //REMOVED BASED ON FEEDBACK FROM CMA
          { text: "Release Number", value: "Release_Number" },
          { text: "Monthly Contract #", value: "Monthly_Contract_#" },
          { text: "Rebill sent", value: "Rebill_Sent" },
          { text: "Rebill", value: "Rebill" },
          { text: "Outgate Date", value: "Outgate_Date" },
          { text: "MSU", value: "MSU" },
          { text: "Notes", value: "Notes" },
          { text: "PAYMENT RECEIVED", value: "PAYMENT_RECEIVED" },
          { text: "Transaction#", value: "Transaction_hash" },
          { text: "Date", value: "Date" },
          { text: "PICK UP EXPIRATION", value: "PICK_UP_EXPIRATION" },
          { text: "Nereus Order ID", value: "nereus_order_id" },
          { text: "Week", value: "week" },
          { text: "Status Code", value: "InboundStatusCode" },
        ],
        "Release Issues": [
          { text: "Release Ref", value: "Release_Ref" },
          { text: "Inventory Count", value: "Inventory_Count" },
          { text: "Count on Order", value: "Count_On_Order" },
          { text: "Order Number", value: "Order_Number" },
        ],

        "Mass Rebill": [
          { text: "Partner Code", value: "Partner Code" },
          { text: "Organization Name", value: "Organization Name" },
          { text: "Street", value: "Street" },
          { text: "City", value: "City" },
          { text: "State/Province/Region", value: "State/Province/Region" },
          { text: "Postal Code", value: "Postal Code" },
          { text: "Name", value: "Name" },
          { text: "Business Email Address", value: "Business Email Address" },
          { text: "Container Number", value: "Container Number" },
          { text: "Size", value: "Size" },
          { text: "Amount", value: "Amount" },
          // { text: "Monthly", value: "Monthly" },
          { text: "Received $ date", value: "Received $ date" },
          { text: "Receipt Number", value: "Receipt Number" },
          { text: "Total amount of receipt", value: "Total amount of receipt" },
          { text: "Rebill number", value: "Rebill number" },
        ],
        "Month End Report": [
          { text: "CONTAINERS", value: "CONTAINERS" },
          { text: "SIZE/TYPE", value: "SIZE/TYPE" },
          { text: "LEASE A. NUMBER", value: "LEASE A. NUMBER" },
          { text: "DEPOTS", value: "DEPOTS" },
          { text: "PRICES", value: "PRICES" },
          { text: "CURRENCY", value: "CURRENCY" },
          { text: "NOTES", value: "estimate_amount" },
        ],
        "Sales Pending Imported": [
          { text: "Container", value: "Container" },
          { text: "Size", value: "Size" },
          { text: "Age", value: "Age" },
          { text: "Amount", value: "Amount" },
          { text: "Location", value: "Location" },
          { text: "Status", value: "Status" },
          { text: "Company Name", value: "Company Name" },
          { text: "Supplier Number", value: "Supplier Number" },
          { text: "Contact Name", value: "Contact Name" },
          { text: "Supplier Number", value: "Supplier Number" },
          { text: "Contact Name", value: "Contact Name" },
          { text: "Date of Quote", value: "Date of Quote" },
          { text: "Date of Quote", value: "Date of Quote" },
          { text: "Date Sold", value: "Date Sold" },
          { text: "Payment Recvd", value: "Payment Recvd" },
          { text: "Release sent DATE", value: "Release sent DATE" },
          { text: "Release", value: "Release" },
          { text: "Release Number", value: "Release Number" },
          { text: "Monthly Contract #", value: "Monthly Contract #" },
          { text: "Rebill sent", value: "Rebill sent" },
          { text: "Rebill", value: "Rebill" },
          { text: "Outgate Date", value: "Outgate Date" },
          { text: "MSU", value: "MSU" },
          { text: "Notes", value: "Notes" },
          { text: "PAYMENT RECEIVED", value: "PAYMENT RECEIVED" },
          { text: "Transaction#", value: "Transaction#" },
          { text: "Date", value: "Date" },
          { text: "Y", value: "Y" },
          { text: "Z", value: "Z" },
          { text: "PICK UP EXPIRATION", value: "PICK UP EXPIRATION" },
          { text: "a_a", value: "a_a" },
          { text: "a_b", value: "a_b" },
          { text: "a_c", value: "a_c" },
          { text: "WEEK", value: "WEEK" },
          { text: "id", value: "id" },
        ],
      };

      const value = tabValues.filter((x) => x.index === this.tab);
      return headerReports[value[0].title];
    },
    header() {
      return generateHeaderData(this.headerReport);
    },
  },
  methods: {
    changeTab(index) {
      this.tab = index;
    },
    changeParentTab(value) {
      this.parentTabValue = value;
      this.tab = 0;
    },
  },
};
</script>

<style></style>
