<template>
  <TableLayout>
    <template v-slot:panel>
      <panel-actions @showSmallTable="showSmallTable"></panel-actions>
    </template>
    <template v-slot:table>
      <v-row class="mx-5 pa-5 fill-height darker_blue" justify="space-around">
        <v-col
          :cols="columnTable"
          class="ma-0 elevation-5 fill-height px-2 dark-blue overflow-y-auto"
          style="max-height: 80vh"
        >
          <OrganizationsTable
            @showSmallTable="showSmallTable"
            :flagNew="flagNew"
            :org_selected="org_selected"
          ></OrganizationsTable>
        </v-col>
        <!-- <v-scale-transition origin="center"> -->
        <v-col
          :cols="8"
          v-if="columnTable !== 12"
          class="ma-0 px-2 elevation-5 dark-blue"
        >
          <ValidationObserver v-slot="{ invalid, reset }" ref="organizationForm">
            <form-organization class="pa-3">
              <template v-slot:logo>
                <logo :formData="formData" :org_selected="org_selected" />
              </template>

              <template v-slot:form>
                <MainCard
                  col="6"
                  :fields="form"
                  :org_selected="org_selected"
                  :account="account"
                />
              </template>

              <template v-slot:tabs="{ height }">
                <tabs
                  :data="address"
                  :org_selected="org_selected"
                  :height="height"
                  class="d-flex flex-column"
                />
              </template>
              <template v-slot:actions-buttons>
                <v-row class="ma-0 pa-2" justify="end" style="max-height: 36px">
                  <v-col class="ma-0 px-2 py-0" cols="2">
                    <v-btn
                      color="#20212E"
                      class="elevation-5 pa-2"
                      block
                      @click="saveOrg"
                      :disabled="invalid"
                    >
                      <span>Save</span>
                    </v-btn>
                  </v-col>
                  <v-col class="ma-0 px-2 py-0" cols="2">
                    <v-btn
                      color="#20212E"
                      class="elevation-5"
                      block
                      @click="resetForm(reset)"
                    >
                      <span>Reset</span>
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
            </form-organization>
          </ValidationObserver>
        </v-col>
        <!-- </v-scale-transition> -->
      </v-row>
    </template>
  </TableLayout>
</template>

<script>
import TableLayout from "@/layout/TableLayout.vue";
import OrganizationsTable from "@/components/Organizations/Table.vue";
import PanelActions from "@/components/Organizations/PanelActions.vue";
import MainCard from "@/components/Organizations/Card2.vue";
import Tabs from "@/components/Organizations/Tabs.vue";
import Logo from "@/components/Organizations/Logo.vue";

import moduleOrganizations from "@/store/modules/organizations/index.js";
import { mapState, mapActions, mapMutations } from "vuex";
import { default_org } from "@/helpers/mocks/organizations.js";
import { ValidationObserver } from "vee-validate";
import FormOrganization from "@/components/Organizations/Form.vue";

export default {
  components: {
    TableLayout,
    OrganizationsTable,
    PanelActions,
    MainCard,
    Tabs,
    Logo,
    ValidationObserver,
    FormOrganization,
  },
  computed: {
    ...mapState({
      list_organizations: (state) => state.moduleOrganizations.list_organizations,
      flagNew: (state) => state.moduleOrganizations.flagNew,
      tabSelected: (state) => state.moduleOrganizations.tabSelected,
      customerAccountBalance: (state) =>
        state.moduleOrders.moduleDetails.customerAccountBalance,
    }),
    form() {
      return [
        {
          title: "Company Name",
          value: "organization_descr",
          rules: "required",
        },
        {
          title: "Type",
          value: "organization_type",
          disable: !this.flagNew,
          selectValues: [
            { title: "Customer", value: "C" },
            { title: "Depot", value: "D" },
          ],
        },
        {
          title: "Email",
          value: "primary_email",
          rules: "required|email",
        },
        {
          title: "Status",
          value: "status",
          selectValues: [
            { title: "Active", value: "Active" },
            { title: "Deactivate", value: "Deactivate" },
            { title: "Suspended", value: "Suspended" },
            { title: "Blacklisted", value: "Blacklisted" },
          ],
        },
        { title: "Phone No.", value: "phone_no", rules: "required" },
        { title: "Tax No.", value: "tax_no" },
        { title: "Company No.", value: "company_no" },
        { title: "Revenue", value: "revenue" },
        { title: "Print Name", value: "PrintName" },
        { title: "Competitors", value: "competitors" },

        { title: "Website", value: "organization_url" },
        { title: "No. of Employees", value: "no_of_employees" },
        {
          title: "External Customer Number",
          value: "externalCustomerNumber",
        },
        {
          title: "Customer Code",
          value: "customer_code",
          rules: "alpha_num|max:10",
        },
      ];
    },
    address() {
      return [
        [
          { title: "Address 1", value: "organization_address" },
          { title: "Address 2", value: "organization_address2" },
          { title: "City", value: "organization_city" },
          { title: "State", value: "organization_state" },
          { title: "Country", value: "organization_country" },
          { title: "Postal Code", value: "organization_postalcode" },
        ],
        [
          { title: "Address 1", value: "ShipAddrLine1" },
          { title: "Address 2", value: "ShipAddrLine2" },
          { title: "City", value: "ShipAddrCity" },
          { title: "State", value: "ShipAddrState" },
          { title: "Country", value: "ShipAddrCountry" },
          { title: "Postal Code", value: "ShipAddrPostalCode" },
        ],
      ];
    },
  },
  mounted() {
    this["moduleOrganizations/getsOrganizations"]();
  },
  data() {
    return {
      columnTable: 12,
      show: true,
      account: 0,

      org_selected: {},
      formData: new FormData(),
    };
  },
  methods: {
    ...mapMutations(["moduleOrganizations/setFlagNew"]),
    ...mapActions([
      "moduleOrganizations/getsOrganizations",
      "moduleOrganizations/createNewOrg",
    ]),
    ...mapActions({
      setTabSelected: "moduleOrganizations/tabSelected",
    }),
    async showSmallTable({ flag, index }) {
      if (flag) {
        //adding new
        this["moduleOrganizations/setFlagNew"](true);
        this.columnTable = 3;
        this.org_selected = { ...default_org };
      } else {
        this["moduleOrganizations/setFlagNew"](false);
        this.setTabSelected({ title: "Billing Address" });

        this.account = this.customerAccountBalance;

        this.columnTable = 3;
        const org = this.list_organizations[index];
        const org_data = {};
        Object.keys(org).map((x) => {
          org_data[x] = `${this.list_organizations[index][x]}`.trim();
        });
        this.org_selected =
          this.columnTable === 3 ? { ...org_data, account: this.account } : {};
      }
      if (this.$refs.organizationForm) this.$refs.organizationForm.reset();
    },
    getOrgById(id) {
      return this.list_organizations.filter(
        ({ organization_id }) => organization_id === id
      );
    },
    async saveOrg() {
      for (let value in this.org_selected) {
        if (value !== "logo" && value !== "logo_filename")
          this.formData.set(`${value}`, this.org_selected[value]);
      }

      try {
        await this["moduleOrganizations/createNewOrg"]({
          data: this.formData,
          flag: this.flagNew,
        });

        // if (this.flagNew) this.org_selected = { ...default_org };
        // else if (!this.getOrgById(this.org_selected.organization_id)[0])
        //   this.org_selected = { ...default_org };
      } catch (error) {
        console.log(error);
      }
    },
    resetForm(cb) {
      this["moduleOrganizations/setFlagNew"](true);

      this.org_selected = { ...default_org };

      cb();
    },
  },
};
</script>

<style></style>
